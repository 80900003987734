<template>
    <div class="ql-snow">
        <div class="ql-editor" v-html="content">{{content}}</div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {

        };
    },
    created() {

    },
    computed: {
        ...mapState(['content'])
    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang='less'>
</style>
